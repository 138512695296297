/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Company from '@/models/Company'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'company',
})
export default class CompanyModule extends VuexModule {
  public data: Company[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public type_options = [
    new SelectOption('Advertiser', 'advertiser'),
    new SelectOption('Publisher', 'publisher'),
    new SelectOption('Agency', 'agency'),
    new SelectOption('Station', 'station'),
  ]

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setOptions(options: SelectOption[]) {
    this.options = options
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(companies: Company | Company[]) {
    // Set input to array
    let data: Array<Company> = []
    if (companies instanceof Company) {
      data.push(companies)
    } else {
      data = companies
    }

    let result = this.data

    // Update Module Data
    data.forEach((company: Company) => {
      let found: boolean = false
      result = result.map((obj: Company) => {
        if (obj.id == company.id) {
          found = true
          return company
        }
        return obj
      })

      if (!found) {
        result.push(company)
      }
    })

    this.data = result

    // Update Module Options
    let { options } = this
    data.forEach((company: Company) => {
      let found: boolean = false
      options = options.map((o: SelectOption) => {
        if (o.value == company.id) {
          found = true
          return company.toOption()
        }
        return o
      })

      if (!found) {
        options.push(company.toOption())
      }
    })

    this.options = options
  }

  @Mutation
  public delete(companies: Company | Company[]) {
    // Set input to array
    let data: Array<Company> = []
    if (companies instanceof Company) {
      data.push(companies)
    } else {
      data = companies
    }

    let result = this.data

    // Remove Object from Module Data
    data.forEach((company: Company) => {
      result = result.filter((obj: Company) => obj.id != company.id)
    })

    this.data = result

    // Remove Object from Module Options
    let { options } = this
    data.forEach((company: Company) => {
      options = options.filter((obj: SelectOption) => obj.value != company.id)
    })

    this.options = options
  }

  @Action
  public async find(id: string): Promise<Company | null> {
    return new Promise(resolve => {
      if (!id) resolve(null)
      const o = this.data.find(company => company.id === id)

      if (o instanceof Company) {
        resolve(o)
      } else {
        return this.api
          .get(`company/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Company.toObject(response.data.result.company)

            if (data instanceof Company) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('companies/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = Company.toObjectList(response.data.result.companies)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  @Action
  public async syncOptions() {
    this.setSynchronized(true)
    return this.api
      .get('companies/option')
      .then(response => {
        // Parse & cache data
        const data = SelectOption.toObjectList(response.data.result.options)

        this.context.commit('setOptions', data)
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('companies/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
