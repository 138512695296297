var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.edit)?_c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',_vm._b({attrs:{"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label-breakpoint":"md","label-for":_vm.actionEdit !== false ? _vm.uuid + 'x' : _vm.uuid,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.formLabel)+" "),_c('span',[(_vm.actionEdit !== false)?_c('IconAction',{attrs:{"icon":"pencil"},on:{"click":function($event){return _vm.$emit('edit')}}}):_vm._e()],1)]},proxy:true},{key:"description",fn:function(){return [_vm._t("description")]},proxy:true}],null,true)},'b-form-group',_vm.$attrs,false),[(_vm.ready)?_c('multiselect',_vm._b({class:{
          'is-invalid': validated && !valid,
          'is-valid': validated && valid,
        },attrs:{"id":_vm.uuid,"name":_vm.uuid,"options":_vm.options,"placeholder":_vm.placeholder,"label":_vm.label_field,"track-by":_vm.label_field,"close-on-select":_vm.close_on_select,"searchable":true,"multiple":_vm.multiple !== false,"hide-selected":_vm.hide_selected,"taggable":_vm.taggable,"disabled":_vm.disabled,"allow-empty":_vm.allow_empty !== false,"internal-search":_vm.internal_search,"loading":_vm.busy,"deselect-label":""},on:{"tag":_vm.addTag,"search-change":_vm.searchChange,"open":_vm.open,"select":function (e) { return _vm.$emit('selected', e); }},model:{value:(_vm.local_value),callback:function ($$v) {_vm.local_value=$$v},expression:"local_value"}},'multiselect',_vm.$attrs,false)):_vm._e()],1)]}}],null,false,3588760060)}):_c('b-form-group',{staticClass:"col-form-label",attrs:{"horizontal":_vm.vertical === false,"label-cols":_vm.vertical === false ? 3 : null,"label":_vm.label,"label-breakpoint":"md"}},[_c('span',[_vm._v(_vm._s(_vm.label_value))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }