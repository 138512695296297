import SystemtModule from '@/store/SystemModule'
import { snakeCase } from 'lodash'
import { getModule } from 'vuex-module-decorators'
import SelectOption from './SelectOption'

export const StatusOptions: any = [
  new SelectOption('Active', 'active'),
  new SelectOption('Inactive', 'inactive'),
]

export const PaginationOptions: SelectOption[] = [
  new SelectOption('10', '10'),
  new SelectOption('25', '25'),
  new SelectOption('50', '50'),
  new SelectOption('100', '100'),
]

export const PaginationOptionsAll: SelectOption[] = [
  ...PaginationOptions,
  new SelectOption('All (Resource intensive)', 'all'),
]

export const OrderTypes: any = [
  new SelectOption('Cash', 'cash'),
  new SelectOption('Trade', 'trade'),
]

export const MediaPlanProposalActions: any = [
  new SelectOption('New', 'New'),
  new SelectOption('Revised', 'Revised'),
]

export const ImpressionModelOptions: any = [
  new SelectOption('CPM', 'cpm'),
  new SelectOption('CPD', 'cpd'),
]

export const CreativeLength: any = [new SelectOption('30s', '30'), new SelectOption('15s', '15')]

export const OrderTypeOptions: any = [
  new SelectOption('Cash', 'cash'),
  new SelectOption('Trade', 'tarde'),
]

export const RedistributeImpressions: any = [
  new SelectOption('No', 'no'),
  new SelectOption('To Cash & Trade Items', 'all'),
  new SelectOption('To Cash Items', 'cash'),
  new SelectOption('To Trade Items', 'trade'),
]

export const TargetOptions: SelectOption[] = [
  new SelectOption('People (P)', 'P'),
  new SelectOption('Male (M)', 'M'),
  new SelectOption('Female (F)', 'F'),
  new SelectOption('Adults (AD)', 'AD'),
]

export const DaysOfWeek: SelectOption[] = [
  new SelectOption('Monday', 'monday'),
  new SelectOption('Tuesday', 'tuesday'),
  new SelectOption('Wednesday', 'wednesday'),
  new SelectOption('Thursday', 'thursday'),
  new SelectOption('Friday', 'friday'),
  new SelectOption('Saturday', 'saturday'),
  new SelectOption('Sunday', 'sunday'),
]

export const MediaPlanTypes: SelectOption[] = [
  new SelectOption('Default', 'default'),
  new SelectOption('Media Ocean', 'media_ocean'),
  new SelectOption('Strata', 'strata'),
]

export const AgeLowOptions: SelectOption[] = [
  new SelectOption('0', '0'),
  new SelectOption('2', '2'),
  new SelectOption('6', '6'),
  new SelectOption('12', '12'),
  new SelectOption('18', '18'),
  new SelectOption('21', '21'),
  new SelectOption('25', '25'),
  new SelectOption('35', '35'),
  new SelectOption('45', '45'),
  new SelectOption('50', '50'),
  new SelectOption('55', '55'),
  new SelectOption('65', '65'),
]

export const AgeHighOptions: SelectOption[] = [
  new SelectOption('5', '5'),
  new SelectOption('11', '11'),
  new SelectOption('17', '17'),
  new SelectOption('20', '20'),
  new SelectOption('24', '24'),
  new SelectOption('34', '34'),
  new SelectOption('44', '44'),
  new SelectOption('49', '49'),
  new SelectOption('54', '54'),
  new SelectOption('64', '64'),
  new SelectOption('99+', '+'),
]

export const MediaPlanViewColumns: SelectOption[] = [
  new SelectOption('Model', 'model'),
  new SelectOption('Net Rate', 'net_rate'),
  new SelectOption('Net Cost', 'net_cost'),
  new SelectOption('Gross Rate', 'gross_rate'),
  new SelectOption('Gross Cost', 'gross_cost'),
  new SelectOption('Max Avails', 'max_avails'),
]

export const MediaPlanViewColumnsLinear: SelectOption[] = [
  new SelectOption('Model', 'model'),
  new SelectOption('Net Rate', 'net_rate'),
  new SelectOption('Net Cost', 'net_cost'),
  new SelectOption('Gross Rate', 'gross_rate'),
  new SelectOption('Gross Cost', 'gross_cost'),
  new SelectOption('Max Avails', 'max_avails'),
  new SelectOption('GRPs', 'grps'),
]

export const InvoiceUploadMode: SelectOption[] = [
  new SelectOption('Invoice Manager (DS)', 'platform'),
  new SelectOption('FTP (OX)', 'ftp'),
]

export const BillingCycle: SelectOption[] = [
  new SelectOption('Calendar Monthly', 'monthly'),
  new SelectOption('Broadcast Month', 'broadcast_month'),
]

export const capitalize = (text: string) => {
  const words = text.split(' ')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  return words.join(' ')
}

export const randomUUID = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const countPlaces = (num: number) => {
  let text = num.toString()
  let index = text.indexOf('.')
  return index == -1 ? 0 : text.length - index - 1
}

export const parseHtmlTable = (text: string) => {
  let match = text.match(/<tr.*?>(.*?)<\/tr>/gims)
  if (match) {
    let data = match
      .map(row => {
        let cells = row.match(/<td.*?>(.*?)<\/td>/gims)
        if (cells) {
          return cells.map(cell => cell.replace(/<.*?>/gims, ''))
        }
        return []
      })
      .filter(row => row.length > 0)
    const headers: any = data.shift()?.map((value: string) => snakeCase(value))

    return data.map(row => {
      let ret: any = {}
      row.forEach((cell, index) => {
        ret[headers[index]] = cell
      })
      return ret
    })
  }

  return []
}

export const cdnPath = process.env.VUE_APP_CDN_PATH

export const sysEnv = (section: string, type: string, key: string) =>
  // @ts-ignore
  getModule(SystemtModule).env_vars[section][type][key]
