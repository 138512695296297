/** @format */

import store from '@/store'
import Company from '@/models/Company'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/model/UserModule'
import ApiParameters from '@/models/interface/ApiParameters'
import Api from './Api'
import Profile from './Profile'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import SelectOption from './interface/SelectOption'
import { tableFields } from './metadata/UserMetadata'

export default class User {
  public api_token: string | null = null

  public company_id: string | null = null

  public created_at: string | null = null

  public email: string = ''

  public phone: string = ''

  public email_verified_at: string | null = null

  public id: string | null = null

  public login_at: string | null = null

  public name: string = ''

  public title: string | null = null

  public photo: string | null = null

  public type: string = 'default'

  public profile_id: string | null = null

  public station_id: string | null = null

  public updated_at: string | null = null

  public home_page: string | null = null

  public report_view_id: string | null = null

  public oauth_accounts: string[] = []

  public metadata: any = {}

  public department: string | null = null

  public get home(): string | null {
    if (
      this.company_id == '8e81bb0e-596b-451d-8126-7c5e2da3737a'
      || this.company_id == '8f8476aa-1d19-4ce0-8595-4f1c7683ae09'
    ) {
      return 'ForecastHome'
    }
    if (this.home_page) return this.home_page
    return this.company!.home_page
  }

  public static toObject(data: any, cache: boolean = true): User {
    const user = new User()

    user.api_token = data.api_token
    user.company_id = data.company_id
    user.created_at = data.created_at
    user.email = data.email
    user.phone = data.phone
    user.email_verified_at = data.email_verified_at
    user.id = data.id
    user.login_at = data.login_at
    user.name = data.name
    user.title = data.title
    user.photo = data.photo
    user.type = data.type
    user.profile_id = data.profile_id
    user.station_id = data.station_id
    user.updated_at = data.updated_at
    user.home_page = data.home_page
    user.report_view_id = data.report_view_id
    user.oauth_accounts = data.oauth_accounts
    user.metadata = data.metadata
    user.department = data.department

    if (data.company) {
      Company.toObject(data.company)
    }

    if (data.station) {
      Company.toObject(data.station)
    }

    if (data.profile) {
      Profile.toObject(data.profile)
    }

    //  Cache Object
    if (cache) User.module.update(user)

    return user
  }

  public static toObjectList(data: any, cache: boolean = true): User[] {
    const users = new Array<User>()
    data.forEach((value: any) => {
      const user = User.toObject(value, false)
      users.push(user)
    })

    //  Cache Object List
    if (cache) User.module.update(users)

    return users
  }

  private _profile: Profile | null = null

  get profile(): Profile | null {
    if (this._profile == null || this._profile.id != this.profile_id) {
      if (this.profile_id != null) {
        this._profile = Profile.find(this.profile_id)
      } else {
        this._profile = new Profile()
      }
    }

    return this._profile
  }

  private _company: Company | null = null

  get company(): Company | null {
    if (this.company_id && (this._company == null || this._company.id != this.company_id)) {
      this._company = Company.find(this.company_id)
    }

    return this._company
  }

  private _station: Company | null = null

  public get station(): Company | null {
    if (this.station_id && (this._station == null || this._station.id != this.station_id)) {
      this._station = Company.find(this.station_id)
    }

    return this._station
  }

  public get first_name() {
    return this.name.split(' ')[0]
  }

  public get last_name() {
    const name = this.name.split(' ')
    return name[name.length - 1]
  }

  public get company_logo() {
    if (this.station && this.station.getLogo()) return this.station.getLogo()
    if (this.company) return this.company.getLogo()

    return null
  }

  public get company_logo_white() {
    if (this.station && this.station.getLogoWhite()) return this.station.getLogoWhite()
    if (this.company) return this.company.getLogoWhite()
    return null
  }

  public get company_icon() {
    if (this.station && this.station.getIcon()) return this.station.getIcon()
    if (this.company) return this.company.getIcon()
    return null
  }

  public typeIn(list: string[]): boolean {
    return list.includes(this.type)
  }

  public get isRoot(): boolean {
    return this.type == 'root'
  }

  public get isSuperAdmin(): boolean {
    if (this.company) return this.company.root

    return false
  }

  public get isAdmin() {
    return this.can('user')
  }

  public get isStation(): boolean {
    return this.station_id != null
  }

  public get isSystem(): boolean {
    return this.type == 'system'
  }

  public can(model: string, action: string = 'any', company_level: boolean = false) {
    if (this.profile) return this.profile.can(model, action, company_level)
    return false
  }

  public save() {
    const api = new Api()

    const data: ApiParameters = {
      name: this.name as string,
      title: this.title as string,
      email: this.email as string,
      phone: this.phone as string,
      company_id: this.company_id as string,
      type: this.type as string,
      profile_id: this.profile_id as string,
      station_id: this.station_id as string | null,
      report_view_id: this.report_view_id as string | null,
      department: this.department as string | null,
    }

    if (this.station_id == null) {
      delete data.station_id
    }

    if (this.id) {
      return api
        .put(`user/${this.id}`, data)
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .post('user', data)
      .then(this.onSave)
      .catch(this.onError)
  }

  public changePassword(data: object) {
    const api = new Api()

    return api
      .patch(`user/${this.id}`, data)
      .then(this.onChangePassword)
      .catch(this.onError)
  }

  public resetPassword() {
    const api = new Api()

    return api
      .post('user/reset-password', {
        email: this.email,
      })
      .then(this.onChangePassword)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`user/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const user = User.toObject(response.data.result.user)

    WebMessage.success(`User "${user.name}" saved!`)

    return response
  }

  public onChangePassword(response: any) {
    WebMessage.success('Password updated!')

    return response
  }

  private onDelete(response: any) {
    const users = User.filter(response.data.result.deleted)

    let message

    if (users.length == 1) {
      message = `User "${users[0].name}" deleted!`
    } else {
      message = 'User deleted!'
    }

    WebMessage.success(message)

    // Remove cached objects
    User.module.delete(users)

    return response
  }

  private onError(error: any) {
    return error
  }

  public toOption(): SelectOption {
    return new SelectOption(
      this.name,
      this.id,
      this.station_id ? this.station_id : this.company_id,
      this.type,
    )
  }

  /// State Management
  public static get module(): UserModule {
    if (!store.hasModule('user')) {
      store.registerModule('user', UserModule)
    }

    return getModule(UserModule)
  }

  public static find(id: string): User | null {
    const o = User.module.data.find(user => user.id === id)
    return o instanceof User ? o : null
  }

  public static filter(filter: any): User[] {
    if (Array.isArray(filter)) {
      return User.module.data.filter(user => user.id && filter.includes(user.id))
    }
    return User.module.data.filter(filter)
  }

  public static async get(id: string): Promise<User | null> {
    return User.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return User.module.paginate(options)
  }

  public static tableFields: any = tableFields
}
