export default {
  'filter-remove': {
    paths: [
      'M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z',
    ],
    style: '',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    height: 110.668,
    width: 122.88,
    x: 0,
    y: 0,
  },
  watch: {
    paths: [
      'M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26.13A12.13,12.13,0,1,1,28.13,16,12.14,12.14,0,0,1,16,28.13Z',
      'M16,6.9a.93.93,0,0,0-.93.93v7.24H9.47a.93.93,0,1,0,0,1.86H16a.92.92,0,0,0,.93-.93V7.83A.93.93,0,0,0,16,6.9Z',
    ],
    style: 'margin-top: -0.2em',
    fill_rule: 'nonzero',
    clip_rule: 'evenodd',
    height: 32,
    width: 32,
    x: 0,
    y: 0,
  },
  stopwatch: {
    paths: [
      'M18.3 8.59l.91-.9a1 1 0 0 0-1.42-1.42l-.9.91a8 8 0 0 0-9.79 0l-.91-.92a1 1 0 0 0-1.42 1.43l.92.91A7.92 7.92 0 0 0 4 13.5a8 8 0 1 0 14.3-4.91zM12 19.5a6 6 0 1 1 6-6a6 6 0 0 1-6 6zm-2-15h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm3 6a1 1 0 0 0-2 0v1.89a1.5 1.5 0 1 0 2 0z',
    ],
    style: 'margin-top: -0.3em',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    strokeWidth: '0.15',
    height: 24,
    width: 24,
    x: 0,
    y: 0,
  },
  'stopwatch-slash': {
    paths: [
      'M10.6 5.63a1 1 0 0 0 .36 2a6.18 6.18 0 0 1 1-.09a6 6 0 0 1 6 6a6.18 6.18 0 0 1-.09 1a1 1 0 0 0 .8 1.16h.18a1 1 0 0 0 1-.82A7.45 7.45 0 0 0 20 13.5a8 8 0 0 0-1.7-4.91l.91-.9a1 1 0 0 0-1.42-1.42l-.9.91A8 8 0 0 0 12 5.5a7.45 7.45 0 0 0-1.4.13zM10 4.5h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm3.49 9.08v-.08v-.08l1.34-1.33a1 1 0 1 0-1.42-1.42L12.08 12h-.16L5.71 5.79a1 1 0 0 0-1.42 1.42l.48.48l.91.91A8 8 0 0 0 16.9 19.82l1.39 1.39a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42zM12 19.5A6 6 0 0 1 7.11 10l3.4 3.39v.08A1.5 1.5 0 0 0 12 15h.08l3.39 3.4A6 6 0 0 1 12 19.5z',
    ],
    style: 'margin-top: -0.3em',
    fill_rule: 'evenodd',
    clip_rule: 'evenodd',
    strokeWidth: '0.15',
    height: 24,
    width: 24,
    x: 0,
    y: 0,
  },
}
