/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import User from '@/models/User'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'user',
})
export default class UserModule extends VuexModule {
  public data: User[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public email_options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setEmailOptions(options: SelectOption[]) {
    this.email_options = options
  }

  @Mutation
  public setOptions(options: SelectOption[]) {
    this.options = options
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(users: User | User[]) {
    // Set input to array
    let data: Array<User> = []
    if (users instanceof User) {
      data.push(users)
    } else {
      data = users
    }

    let result = this.data

    // Update Module Data
    data.forEach((user: User) => {
      let found: boolean = false
      result = result.map((obj: User) => {
        if (obj.id == user.id) {
          found = true
          return user
        }
        return obj
      })

      if (!found) {
        result.push(user)
      }
    })

    this.data = result

    // Update Module Options
    let { options } = this
    data.forEach((user: User) => {
      let found: boolean = false
      options = options.map((o: SelectOption) => {
        if (o.value == user.id) {
          found = true
          return user.toOption()
        }
        return o
      })

      if (!found) {
        options.push(user.toOption())
      }
    })

    this.options = options

    // Update Module email Options
    let { email_options } = this
    data.forEach((user: User) => {
      let found: boolean = false
      email_options = email_options.map((o: SelectOption) => {
        if (o.value == user.email) {
          found = true
          return new SelectOption(user.email, user.email)
        }
        return o
      })

      if (!found) {
        email_options.push(user.toOption())
      }
    })
    this.email_options = email_options
  }

  @Mutation
  public delete(users: User | User[]) {
    // Set input to array
    let data: Array<User> = []
    if (users instanceof User) {
      data.push(users)
    } else {
      data = users
    }

    let result = this.data

    // Remove Object from Module Data
    data.forEach((user: User) => {
      result = result.filter((obj: User) => obj.id != user.id)
    })

    this.data = result

    // Remove Object from Module Options
    let { options } = this
    data.forEach((user: User) => {
      options = options.filter((obj: SelectOption) => obj.value != user.id)
    })

    this.options = options

    // Remove Object from Module Email Options
    let { email_options } = this
    data.forEach((user: User) => {
      email_options = email_options.filter((obj: SelectOption) => obj.value != user.email)
    })

    this.email_options = email_options
  }

  @Action
  public async find(id: string): Promise<User | null> {
    return new Promise(resolve => {
      const o = this.data.find(user => user.id === id)

      if (o instanceof User) {
        resolve(o)
      } else {
        return this.api
          .get(`user/${id}`)
          .then(response => {
            // Parse & cache data
            const data = User.toObject(response.data.result.user)

            if (data instanceof User) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('users/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = User.toObjectList(response.data.result.users)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  @Action
  public async syncOptions() {
    this.setSynchronized(true)
    return this.api
      .get('users/option')
      .then(response => {
        // Parse & cache data
        let data = SelectOption.toObjectList(response.data.result.options)
        this.context.commit('setOptions', data)

        data = new Array<SelectOption>()
        response.data.result.options.forEach((value: any) => {
          const o = new SelectOption(value.email, value.email)
          data.push(o)
        })
        this.context.commit('setEmailOptions', data)
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('users/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
