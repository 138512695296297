import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import DataTableField from '@/components/DataTable/DataTableField'
import DataTableAction from '@/components/DataTable/DataTableAction'
import { startCase } from 'lodash'
import DataTableDateField from '@/components/DataTable/DataTableDateField'
import DataTableActionField from '@/components/DataTable/DataTableActionField'
import User from '../User'
import WebMessage from '../WebMessage'

export const model_name = 'user'

export const tableFields: Array<DataTableField> = [
  new DataTableField('name', 'Name'),
  new DataTableField('title', 'Title'),
  new DataTableField('email', 'Email'),
  new DataTableField('phone', 'Phone', 'text', false, false),
  new DataTableField(
    'department',
    'Department',
    'capitalize',
    false,
    true,
    'align-middle text-center',
    null,
    value => (value ? startCase(value.replaceAll('_', ' ')) : 'N/A'),
  ),
  new DataTableField('type', 'Type', 'capitalize', false),
  new DataTableField('company', 'Company', 'object', true, true, 'align-middle text-center', {
    key: 'company',
    property: 'name',
  }),
  new DataTableField('station', 'Station', 'object', true, true, 'align-middle text-center', {
    key: 'station',
    property: 'name',
  }),
  new DataTableField('profile', 'Profile', 'object', true, true, 'align-middle text-center', {
    key: 'profile',
    property: 'name',
  }),
  new DataTableDateField('created_at', 'Created At'),
  new DataTableDateField('updated_at', 'Updated At', false, false),
  new DataTableDateField('login_at', 'Last Login', false, false),
  new DataTableActionField([
    new DataTableAction(
      'Edit User',
      'edit-user',
      'pencil',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit')) as boolean
      },
      (user: User) => {
        let ref = window.location.href.includes('my-dashboard') ? '&ref=MyDashboard' : ''
        router.push(`/app/user/${user.id}${ref}`)
      },
    ),
    new DataTableAction(
      'Reset Password',
      'password-reset',
      'lock',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'edit') && user.isSuperAdmin) as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to reset the user's "<strong>${target.name}<${target.email}}></strong>" password?`,
          'Reset Password',
        ).then((value: boolean) => {
          if (value) {
            return target.resetPassword()
          }
        }),
    ),
    new DataTableAction(
      'Delete User',
      'delete-user',
      'trash',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('user', 'delete')) as boolean
      },
      (target: User) =>
        WebMessage.confirm(
          `Are you sure that you want to delete the user "<strong>${target.name}<${target.email}}></strong>"? You won't be able to restore it!`,
          'Delete User',
        ).then((value: boolean) => {
          if (value) {
            return target.delete()
          }
        }),
    ),
  ]),
]
