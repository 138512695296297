import store from '@/store'

export default {
  data() {
    return {

    }
  },
  methods: {
    normalizePublisherName(target: string) {
      return target
      const publisher = target.toLowerCase()
      if (publisher.includes('mlb')) {
        return 'MLB.TV'
      } if (publisher.includes('nhl')) {
        return 'NHL.TV'
      } if (publisher.includes('nba')) {
        return 'NBA.TV'
      }

      return target
    },
  },
  filters: {
    abbreviate_full(n: any) {
      const a = n.slice(-1)
      switch (a) {
        case 'm':
          n = n.replace('m', ' Million')
          break
        case 'b':
          n = n.replace('b', ' Billion')
          break
        case 't':
          n = n.replace('t', ' Trillion')
          break
      }
      return n
    },
  },
}
