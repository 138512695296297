/** @format */

// System
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Vue from 'vue'
import OneSignalVue from 'onesignal-vue'

// Bootstrap
import {
  LayoutPlugin,
  CardPlugin,
  ModalPlugin,
  TablePlugin,
  SidebarPlugin,
  NavbarPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  ListGroupPlugin,
  ButtonPlugin,
  LinkPlugin,
  PaginationPlugin,
  CollapsePlugin,
  BadgePlugin,
  TooltipPlugin,
  InputGroupPlugin,
  SpinnerPlugin,
  AlertPlugin,
  FormRadioPlugin,
  TabsPlugin,
  ProgressPlugin,
  FormTextareaPlugin,
  CarouselPlugin,
  FormSpinbuttonPlugin,
  FormSelectPlugin,
  PopoverPlugin,
  AvatarPlugin,
} from 'bootstrap-vue/esm'

// @ts-ignore
import Ripple from 'vue-ripple-directive'

// Vee Validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  numeric,
  between,
  image,
  min_value,
  max_value,
} from 'vee-validate/dist/rules'

import './filters'

// @ts-ignore
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import Vue2Filters from 'vue2-filters'

import './registerServiceWorker'
import Snotify from 'vue-snotify'
// @ts-ignore
import VueEcho from 'vue-echo'

// Recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'

import LayoutModule from '@/store/LayoutModule'
import SystemModule from '@/store/SystemModule'
import VueAxios from './plugins/axios'
import util from './mixins/util'
import App from './App.vue'
import layoutMixin from './mixins/layout'
import router from './Routes'

Vue.use(AvatarPlugin)

Vue.use(OneSignalVue)
// SidePanel Component
Vue.use(LayoutPlugin)
// Cards Component
Vue.use(CardPlugin)
// Modal Component
Vue.use(ModalPlugin)
// Table Component
Vue.use(TablePlugin)
// SidePanel Component
Vue.use(SidebarPlugin)
// Navbar Component
Vue.use(NavbarPlugin)
// Form Component
Vue.use(FormPlugin)
// FormCheckbox Component
Vue.use(FormCheckboxPlugin)
// FormFile Component
Vue.use(FormFilePlugin)
// FormGroup Component
Vue.use(FormGroupPlugin)
// FormInput Component
Vue.use(FormInputPlugin)
// ListGroup Component
Vue.use(ListGroupPlugin)
// Button Component
Vue.use(ButtonPlugin)
// Link Component
Vue.use(LinkPlugin)
// Pagination Component
Vue.use(PaginationPlugin)
// Collapse Component
Vue.use(CollapsePlugin)
// Badge Component
Vue.use(BadgePlugin)
// Tooltip Component
Vue.use(TooltipPlugin)
// InputGroup Component
Vue.use(InputGroupPlugin)
// Spinner Component
Vue.use(SpinnerPlugin)
// AlertPlugin Component
Vue.use(AlertPlugin)
// Tabs Component
Vue.use(TabsPlugin)
// FormRadio Component
Vue.use(FormRadioPlugin)
// Progress Component
Vue.use(ProgressPlugin)
// FormTextarea Component
Vue.use(FormTextareaPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormSpinbuttonPlugin)
Vue.use(FormSelectPlugin)
Vue.use(PopoverPlugin)

Vue.directive('ripple', Ripple)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
extend('email', {
  ...email,
  message: 'The {_field_} field must be a valid email',
})
extend('required', { ...required, message: 'The {_field_} field is required' })
extend('numeric', {
  ...numeric,
  message: 'The {_field_} field may only contain numeric characters',
})
extend('min', {
  ...min,
  message: 'The {_field_} field must be at least {length} characters',
})
extend('max', {
  ...max,
  message: 'The {_field_} field may not be greater than {length} characters',
})
extend('min_value', {
  ...min_value,
  message: 'The {_field_} field must be at least {length} characters',
})
extend('max_value', {
  ...max_value,
  message: 'The {_field_} field may not be greater than {length} characters',
})
extend('between', {
  ...between,
  message: 'The {_field_} field must be between {min} and {max}',
})
extend('image', { ...image, message: 'The {_field_} field must be an image' })
extend('is', {
  params: ['target'],
  validate: (value, confirm: any) => value === confirm.target,
  message: 'The {_field_} field must match the {target}',
})
extend('is_not', {
  params: ['target'],
  validate: (value, confirm: any) => value !== confirm.target,
  message: 'The {_field_} field must NOT match the {target}',
})
extend('https', {
  validate: value =>
    /^(?:https:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}%]+$/.test(value),
  message: 'The {_field_} field must be a valid https url',
})
Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' })
Vue.use(Vue2Filters)

Vue.use(Snotify, {
  toast: {
    timeout: 5000,
  },
})
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})

// @ts-ignore
window.Pusher = require('pusher-js')

Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHERKEY,
  cluster: 'mt1',
  forceTLS: true,
  authEndpoint: `${process.env.VUE_APP_BASE_API_URL}broadcasting/auth`,
  auth: {
    headers: { Authorization: '' },
  },
})
Vue.use(require('vue-moment'))

Vue.use(VueAxios)

/* Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
    },
}); */

Vue.mixin(layoutMixin)
Vue.mixin(util)

Vue.config.productionTip = false

if (!store.hasModule('layout')) {
  store.registerModule('layout', LayoutModule)
}

if (!store.hasModule('system')) {
  store.registerModule('system', SystemModule)
}

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  router,
  beforeMount() {
    this.$OneSignal.init({
      appId: 'ca0c9ea2-2f51-4e85-99c0-c2d2ab47b81e',
      safari_web_id: '',
      notifyButton: {
        enable: false,
      },
    })
  },
  async created() {
    let token = ''
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // @ts-ignore
      token = await this.$recaptcha('login')
    }

    const system = getModule(SystemModule, store)
    system.init(token)
  },
  render: h => h(App),
})
