/** @format */
import FeatureAccess from './FeatureAccess'

export default [
  //  Insights
  new FeatureAccess('insights', 'view'),

  //  AdOps
  new FeatureAccess('adops', 'view'),

  //  Companies
  new FeatureAccess('company', 'view'),
  new FeatureAccess('company', 'edit'),

  // Users
  new FeatureAccess('user', 'view'),
  new FeatureAccess('user', 'edit'),
  new FeatureAccess('user', 'delete'),

  // Profiles
  new FeatureAccess('profile', 'view'),
  new FeatureAccess('profile', 'edit'),
  new FeatureAccess('profile', 'delete'),

  //  Campaigns
  new FeatureAccess('campaign', 'view'),

  //  Media Package
  new FeatureAccess('media_package', 'view'),
  new FeatureAccess('media_package', 'edit'),
  new FeatureAccess('media_package', 'delete'),

  //  Media Plan
  new FeatureAccess('media_plan', 'view'),
  new FeatureAccess('media_plan', 'review'),
  new FeatureAccess('media_plan', 'edit'),
  new FeatureAccess('media_plan', 'delete'),

  //  Booking
  new FeatureAccess('booking', 'view'),
  new FeatureAccess('booking', 'edit'),
  new FeatureAccess('booking', 'delete'),

  //  Inventory
  new FeatureAccess('inventory', 'view'),
  new FeatureAccess('inventory', 'edit'),

  //  Scheduler
  new FeatureAccess('scheduler', 'view'),
  new FeatureAccess('scheduler', 'edit'),
  new FeatureAccess('scheduler', 'delete'),

  //  Creative
  new FeatureAccess('creative', 'view'),
  new FeatureAccess('creative', 'edit'),

  //  Forecast
  new FeatureAccess('forecast', 'view'),
  new FeatureAccess('forecast', 'edit'),

  //  Reports
  new FeatureAccess('report', 'view'),
  new FeatureAccess('report', 'edit'),

  // Report Views
  new FeatureAccess('report_view', 'view'),
  new FeatureAccess('report_view', 'edit'),
  new FeatureAccess('report_view', 'delete'),

  //  Insertion Order
  new FeatureAccess('insertion_order', 'view'),
  new FeatureAccess('insertion_order', 'edit'),
  new FeatureAccess('insertion_order', 'delete'),

  //  Agreement
  new FeatureAccess('agreement', 'view'),
  new FeatureAccess('agreement', 'edit'),
  new FeatureAccess('agreement', 'delete'),

  //  Terms
  new FeatureAccess('term', 'view'),
  new FeatureAccess('term', 'edit'),
  new FeatureAccess('term', 'delete'),

  //  Campaign Request
  new FeatureAccess('campaign_request', 'view'),
  new FeatureAccess('campaign_request', 'edit'),

  //  Reconciliation
  new FeatureAccess('reconciliation', 'view'),
  new FeatureAccess('reconciliation', 'edit'),
  new FeatureAccess('reconciliation', 'delete'),

  //  Dynamic Rate
  new FeatureAccess('dynamic_rate', 'view'),
  new FeatureAccess('dynamic_rate', 'edit'),
  new FeatureAccess('dynamic_rate', 'delete'),

  //  Invoices
  new FeatureAccess('invoice', 'view'),
  new FeatureAccess('invoice', 'edit'),
  new FeatureAccess('invoice', 'delete'),

  //  Media Ocean
  new FeatureAccess('media_ocean', 'view'),
  new FeatureAccess('media_ocean', 'edit'),
  new FeatureAccess('media_ocean', 'delete'),

  //  Media Ocean
  new FeatureAccess('task', 'view'),
  new FeatureAccess('task', 'edit'),
  new FeatureAccess('task', 'delete'),

  //  Finances
  new FeatureAccess('finance', 'view'),

  //  Audits
  new FeatureAccess('audit', 'view'),

  //  Debug
  new FeatureAccess('debug', 'view'),
]
