/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Profile from '@/models/Profile'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'profile',
})
export default class ProfileModule extends VuexModule {
  public data: Profile[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setOptions(options: SelectOption[]) {
    this.options = options
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(profiles: Profile | Profile[]) {
    // Set input to array
    let data: Array<Profile> = []
    if (profiles instanceof Profile) {
      data.push(profiles)
    } else {
      data = profiles
    }

    let result = this.data

    // Update Module Data
    data.forEach((profile: Profile) => {
      let found: boolean = false
      result = result.map((obj: Profile) => {
        if (obj.id == profile.id) {
          found = true
          return profile
        }
        return obj
      })

      if (!found) {
        result.push(profile)
      }
    })

    this.data = result

    // Update Module Options
    let { options } = this
    data.forEach((profile: Profile) => {
      let found: boolean = false
      options = options.map((o: SelectOption) => {
        if (o.value == profile.id) {
          found = true
          return profile.toOption()
        }
        return o
      })

      if (!found) {
        options.push(profile.toOption())
      }
    })

    this.options = options
  }

  @Mutation
  public delete(profiles: Profile | Profile[]) {
    // Set input to array
    let data: Array<Profile> = []
    if (profiles instanceof Profile) {
      data.push(profiles)
    } else {
      data = profiles
    }

    let result = this.data

    // Remove Object from Module Data
    data.forEach((profile: Profile) => {
      result = result.filter((obj: Profile) => obj.id != profile.id)
    })

    this.data = result

    // Remove Object from Module Options
    let { options } = this
    data.forEach((profile: Profile) => {
      options = options.filter((obj: SelectOption) => obj.value != profile.id)
    })

    this.options = options
  }

  @Action
  public async find(id: string): Promise<Profile | null> {
    return new Promise(resolve => {
      const o = this.data.find(profile => profile.id === id)

      if (o instanceof Profile) {
        resolve(o)
      } else {
        return this.api
          .get(`profile/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Profile.toObject(response.data.result.profile)

            if (data instanceof Profile) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('profiles/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = Profile.toObjectList(response.data.result.profiles)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  @Action
  public async syncOptions() {
    this.setSynchronized(true)
    return this.api
      .get('profiles/option')
      .then(response => {
        // Parse & cache data
        const data = SelectOption.toObjectList(response.data.result.options)

        this.context.commit('setOptions', data)
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('profiles/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
