import DataTableField, { DataTableFormatterHandler } from './DataTableField'

/**
 * Creates a new columns in the DataTable of type Date
 */
export default class DataTableDateField extends DataTableField {
  constructor(key:string, label:string, show : boolean = true, sortable : boolean = true, formatter: null | DataTableFormatterHandler = null, tdClass : string = 'align-middle text-center') {
    super(key, label, 'date', show, sortable, tdClass, null, formatter)
  }
}
