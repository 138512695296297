/** @format */

import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import ProfileModule from '@/store/model/ProfileModule'
import { BIconArrowReturnLeft } from 'bootstrap-vue/esm'
import ApiParameters from '@/models/interface/ApiParameters'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import Api from './Api'
import Company from './Company'
import FeatureAccess from './FeatureAccess'
import defaultPermissions from './DefaultPermissions'

export default class Profile {
  public id: string | null = null;

  public name: string = '';

  public company_id: string = '';

  public station_id: string = '';

  public default: boolean = false;

  public system: boolean = false;

  public root: boolean = false;

  public created_at: string = '';

  public updated_at: string = '';

  public access: FeatureAccess[] = JSON.parse(
    JSON.stringify(defaultPermissions),
  );

  private _company: Company | null = null;

  get company(): Company | null {
    if (
      this.company_id
      && (!this._company || this.company_id != this._company.id)
    ) {
      this._company = Company.find(this.company_id)
    }

    return this._company
  }

  private _station: Company | null = null;

  get station(): Company | null {
    if (
      this.station_id
      && (!this._station || this.station_id != this._station.id)
    ) {
      this._station = Company.find(this.station_id)
    }

    return this._station
  }

  public getAccessIndex(model: string, action: string) {
    return this.access.findIndex(
      item => item.model === model && item.action === action,
    )
  }

  public can(
    model: string,
    action: string = 'any',
    company_level: boolean = false,
  ) {
    let permission
    if (action == 'any') {
      permission = this.company?.access.find(
        p => p.model === model && p.access === true,
      )
    } else {
      permission = this.company?.access.find(
        p => p.model === model && p.action === action,
      )
    }

    if (!permission || company_level) {
      return permission ? permission.access : false
    }

    if (action == 'any') {
      permission = this.access.find(
        p => p.model === model && p.access === true,
      )
    } else {
      permission = this.access.find(
        p => p.model === model && p.action === action,
      )
    }

    if (!permission) {
      return false
    }
    return permission.access
  }

  private _actions: string[] = [];

  public get actions() {
    if (this._actions.length == 0) {
      this._actions = this.access
        .map(item => item.action)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    return this._actions
  }

  private _models: string[] = [];

  public get models() {
    if (this._models.length == 0) {
      this._models = this.access
        .map(item => item.model)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    return this._models
  }

  public hasAction(model: string, action: string) {
    return this.access.some(
      item => item.model === model && item.action === action,
    )
  }

  public save(logo: any = null, logo_white: any = null, icon: any = null) {
    const api = new Api()

    const data: ApiParameters = {
      name: this.name,
      company_id: this.company_id,
      station_id: this.station_id,
      access: this.access
        .filter(item => item.access)
        .map(item => `${item.model}:${item.action}`),
    }

    if (!this.station_id) {
      delete data.station_id
    }

    if (this.id) {
      return api.put(`profile/${this.id}`, data).then(this.onSave)
    }
    return api.post('profile', data).then(this.onSave)
  }

  public delete() {
    /*
        @TODO: Push validation to serverside
        if (store.state.database.users.some((o: User) => o.profile_id === this.id)) {
            WebMessage.error('Please remove all users before deleting the profile.');
            return Promise.reject('error');
        } */
    const api = new Api()

    return api.delete(`profile/${this.id}`, {}).then(this.onDelete)
  }

  private onSave(response: any) {
    const profile = Profile.toObject(response.data.result.profile)

    WebMessage.success(`Profile "${profile.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const profiles = Profile.filter(response.data.result.deleted)

    let message

    if (profiles.length == 1) {
      message = `Profile "${profiles[0].name}" deleted!`
    } else {
      message = 'Profile deleted!'
    }

    WebMessage.success(message)

    Profile.module.delete(profiles)

    return response
  }

  public static toObject(data: any, cache: boolean = true): Profile {
    const profile = new Profile()

    profile.id = data.id
    profile.name = data.name
    profile.company_id = data.company_id
    profile.station_id = data.station_id
    profile.default = data.default
    profile.system = data.system
    profile.root = data.root
    profile.created_at = data.created_at
    profile.updated_at = data.updated_at

    for (const model in data.access) {
      for (const idx in data.access[model]) {
        const action = data.access[model][idx]
        const index = profile.getAccessIndex(model, action)
        if (index >= 0) profile.access[index].access = true
      }
    }

    if (data.company) {
      Company.toObject(data.company)
    }

    if (data.station) {
      Company.toObject(data.station)
    }

    //  Cache Object
    if (cache) Profile.module.update(profile)

    return profile
  }

  public static toObjectList(data: any, cache: boolean = true): Profile[] {
    const profiles = new Array<Profile>()
    data.forEach((value: any) => {
      const profile = Profile.toObject(value, false)
      if (profile instanceof Profile) profiles.push(profile)
    })

    //  Cache Object List
    if (cache) Profile.module.update(profiles)

    return profiles
  }

  public toOption(): SelectOption {
    return new SelectOption(
      this.name,
      this.id,
      this.station_id ? this.station_id : this.company_id,
    )
  }

  /// State Management
  public static get module(): ProfileModule {
    if (!store.hasModule('profile')) {
      store.registerModule('profile', ProfileModule)
    }

    return getModule(ProfileModule)
  }

  public static find(id: string): Profile | null {
    const o = Profile.module.data.find(profile => profile.id === id)
    return o instanceof Profile ? o : null
  }

  public static filter(ids: string[]): Profile[] {
    return Profile.module.data.filter(
      profile => profile.id && ids.includes(profile.id),
    )
  }

  public static async get(id: string): Promise<Profile | null> {
    return Profile.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return Profile.module.paginate(options)
  }
}
