/** @format */

import store from '@/store'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import CompanyModule from '@/store/model/CompanyModule'
import ApiParameters from '@/models/interface/ApiParameters'
import InsightsSettings from '@/models/InsightsSettings'
import PaginateOptions from './interface/PaginateOptions'
import defaultPermissions from './DefaultPermissions'
import WebMessage from './WebMessage'
import FeatureAccess from './FeatureAccess'

export default class Company {
  public id: string | null = null

  public name: string = ''

  public type: string = 'advertiser'

  public parent_id: string | null = null

  public salesforce_id: string | null = null

  public owner_id: string | null = null

  public invoicing_account_id: string | null = null

  public logo: string | null = null

  public icon: string | null = null

  public logo_white: string | null = null

  public country: string | null = 'US'

  public state: string | null = null

  public invoicing_id: string | null = null

  public city: string | null = null

  public zipcode: string | null = null

  public address: string | null = null

  public billing_email: string | null = null

  public billing_phone: string | null = null

  public root: boolean = false

  public rate: number = 20

  public home_page: string = 'Insights'

  public created_at: string = '';

  public updated_at: string = '';

  public access: FeatureAccess[] = JSON.parse(
    JSON.stringify(defaultPermissions),
  )

  public insights_settings: InsightsSettings | null = new InsightsSettings()

  public getAccessIndex(model: string, action: string) {
    return this.access.findIndex(
      item => item.model === model && item.action === action,
    )
  }

  private _parent: Company | null = null

  get parent(): Company | null {
    if (
      this.parent_id
      && (this._parent == null || this._parent.id != this.parent_id)
    ) {
      this._parent = Company.find(this.parent_id)
    }

    return this._parent
  }

  private _owner: Company | null = null

  get owner(): Company | null {
    if (
      this.owner_id
      && (this._owner == null || this._owner.id != this.owner_id)
    ) {
      this._owner = Company.find(this.owner_id)
    }

    return this._owner
  }

  get has_stations(): boolean {
    return Company.module.data.some((o: Company) => o.parent_id === this.id)
  }

  public get typeFormatted() {
    let { type } = this
    if (type == 'agency') type = 'media_seller'
    return type.replace('_', ' ')
  }

  public getLogo() {
    return this.logo
  }

  public getLogoWhite() {
    if (this.logo_white) return this.logo_white
    return this.getLogo()
  }

  public getIcon() {
    if (this.icon) return this.icon
    return this.getLogo()
  }

  public get isBillingInformationComplete() {
    return (
      this.country
      && this.state
      && this.city
      && this.zipcode
      && this.address
      && this.billing_email
      && this.billing_phone
    )
  }

  public insightStatus(widget: string, metric: string = 'enabled'): boolean {
    if (
      this.insights_settings !== null
      && widget in this.insights_settings
      // @ts-ignore
      && metric in this.insights_settings[widget]
    ) {
      // @ts-ignore
      return this.insights_settings[widget][metric]
    }

    if (this.parent) {
      return this.parent.insightStatus(widget, metric)
    }

    if (this.owner) {
      return this.owner.insightStatus(widget, metric)
    }

    return false
  }

  public can(model: string, action: string = 'any') {
    if (this.root) return true
    let permission

    if (action == 'any') {
      permission = this.access.find(
        p => p.model === model && p.access === true,
      )
    } else {
      permission = this.access.find(
        p => p.model === model && p.action === action,
      )
    }

    if (!permission) {
      return false
    }
    return permission.access
  }

  public save(logo: any = null, logo_white: any = null, icon: any = null) {
    const api = new Api()

    const data: ApiParameters = {
      name: this.name,
      salesforce_id: this.salesforce_id,
      type: this.type,
      access: this.access
        .filter(item => item.access)
        .map(item => `${item.model}:${item.action}`)
        .join(','),
      rate: this.rate,
      country: this.country,
      state: this.state,
      city: this.city,
      zipcode: this.zipcode,
      address: this.address,
      billing_email: this.billing_email,
      billing_phone: this.billing_phone,
      parent_id: this.parent_id,
      owner_id: this.owner_id,
      logo: this.logo,
      logo_white: this.logo_white,
      icon: this.icon,
      logo_file: logo,
      logo_white_file: logo_white,
      icon_file: icon,
      invoicing_id: this.invoicing_id,
      insights_settings:
        this.insights_settings !== null
          ? JSON.stringify(this.insights_settings)
          : null,
    }

    if (this.access.filter(item => item.access).length == 0) {
      data.access = ''
    }

    if (!this.country) {
      delete data.country
    }

    if (!this.state) {
      delete data.state
    }

    if (!this.city) {
      delete data.city
    }

    if (!this.zipcode) {
      delete data.zipcode
    }

    if (!this.address) {
      delete data.address
    }

    if (!this.billing_email) {
      delete data.billing_email
    }

    if (!this.billing_phone) {
      delete data.billing_phone
    }

    if (this.type != 'station') {
      delete data.parent_id
    }
    if (this.type != 'advertiser') {
      delete data.owner_id
    }

    if (!this.logo) {
      delete data.logo
    }

    if (!this.logo_white) {
      delete data.logo_white
    }

    if (!this.icon) {
      delete data.icon
    }

    if (!logo) delete data.logo_file
    if (!logo_white) delete data.logo_white_file
    if (!icon) delete data.icon_file

    if (!this.invoicing_id) {
      delete data.invoicing_id
    }

    if (this.id) {
      return api
        .form(`company/${this.id}`, data)
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .form('company', data)
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`company/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const company = Company.toObject(response.data.result.company)

    WebMessage.success(`Entity "${company.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const companies = Company.filter(response.data.result.deleted)

    let message: string

    if (companies.length == 1) {
      message = `Entity "${companies[0].name}" deleted!`
    } else {
      message = 'Entity deleted!'
    }

    WebMessage.success(message)

    Company.module.delete(companies)

    return response
  }

  private onError(error: any) {
    return error
  }

  public static toObject(data: any, cache: boolean = true): Company {
    const company = new Company()

    company.id = data.id
    company.name = data.name
    company.salesforce_id = data.salesforce_id
    company.type = data.type
    company.parent_id = data.parent_id
    company.owner_id = data.owner_id
    company.logo = data.logo
    company.icon = data.icon
    company.logo_white = data.logo_white
    company.country = data.country
    company.state = data.state
    company.invoicing_id = data.invoicing_id
    company.city = data.city
    company.zipcode = data.zipcode
    company.address = data.address
    company.billing_email = data.billing_email
    company.billing_phone = data.billing_phone
    company.root = data.root
    company.rate = data.rate
    company.created_at = data.created_at
    company.updated_at = data.updated_at
    if (data.insights_settings) company.insights_settings = data.insights_settings
    if (data.home_page) company.home_page = data.home_page

    for (const model in data.access) {
      for (const idx in data.access[model]) {
        const action = data.access[model][idx]
        const index = company.getAccessIndex(model, action)
        if (index >= 0) company.access[index].access = true
      }
    }

    if (data.parent) {
      Company.toObject(data.parent)
    }

    if (data.owner) {
      Company.toObject(data.owner)
    }

    //  Cache Object
    if (cache) Company.module.update(company)

    return company
  }

  public static toObjectList(data: any, cache: boolean = true): Company[] {
    const companies = new Array<Company>()
    data.forEach((value: any) => {
      companies.push(Company.toObject(value, false))
    })

    //  Cache Object List
    if (cache) Company.module.update(companies)

    return companies
  }

  public toOption(): SelectOption {
    return new SelectOption(
      this.name,
      this.id,
      this.parent_id ? this.parent_id : this.owner_id,
      this.type,
    )
  }

  // State Management
  public static get module(): CompanyModule {
    if (!store.hasModule('company')) {
      store.registerModule('company', CompanyModule)
    }

    return getModule(CompanyModule)
  }

  public static find(id: string): Company | null {
    const o = Company.module.data.find(company => company.id === id)
    return o instanceof Company ? o : null
  }

  public static filter(filter: any): Company[] {
    if (Array.isArray(filter)) {
      return Company.module.data.filter(
        company => company.id && filter.includes(company.id),
      )
    }
    return Company.module.data.filter(filter)
  }

  public static async get(id: string): Promise<Company | null> {
    return Company.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return Company.module.paginate(options)
  }
}
