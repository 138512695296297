
























import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Error extends Vue {
  public goHome() {
    this.$router.push('/app/dashboard')
  }
}
