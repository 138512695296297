export default class FeatureAccess {
    public model!: string;

    public action !: string;

    public access: boolean = false;

    constructor(model: string, action: string, access: boolean = false) {
      this.model = model
      this.action = action
      this.access = access
    }
}
