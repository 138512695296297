import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Login from '@/pages/Login/Login.vue'
import ErrorPage from '@/pages/Error/Error.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from './store/SystemModule'

// Layout
const Layout = () => import('@/components/Layout/Layout.vue')

// Insights
// const InsightsLegacy = () => import(/* webpackChunkName: "chunk-insights-legacy" */ '@/pages/InsightsLegacy/Insights.vue'); // Legacy Insights
const Insights = () => import(/* webpackChunkName: "chunk-insights" */ '@/pages/Insights/index.vue')

// Company
const CompanyHome = () =>
  import(/* webpackChunkName: "chunk-companies" */ '@/pages/Company/CompanyHome.vue')
const CompanyEdit = () =>
  import(/* webpackChunkName: "chunk-companies" */ '@/pages/Company/CompanyEdit.vue')

// Account
const UserAccount = () =>
  import(/* webpackChunkName: "chunk-account" */ '@/pages/User/UserAccount.vue')

// Users
const UserHome = () => import(/* webpackChunkName: "chunk-users" */ '@/pages/User/UserHome.vue')
const UserEdit = () => import(/* webpackChunkName: "chunk-users" */ '@/pages/User/UserEdit.vue')

// Profile
const ProfileHome = () =>
  import(/* webpackChunkName: "chunk-profile" */ '@/pages/Profile/ProfileHome.vue')
const ProfileEdit = () =>
  import(/* webpackChunkName: "chunk-profile" */ '@/pages/Profile/ProfileEdit.vue')

// Reports
const ReportHome = () =>
  import(/* webpackChunkName: "chunk-reports" */ '@/pages/Report/ReportHome.vue')
const ReportEdit = () =>
  import(/* webpackChunkName: "chunk-reports" */ '@/pages/Report/ReportEdit.vue')

// Terms
const TermsHome = () => import(/* webpackChunkName: "chunk-terms" */ '@/pages/Terms/TermsHome.vue')
const TermsEdit = () => import(/* webpackChunkName: "chunk-terms" */ '@/pages/Terms/TermsEdit.vue')

// Closeout
const CloseoutHome = () =>
  import(/* webpackChunkName: "chunk-closeout" */ '@/pages/Closeout/CloseoutHome.vue')
const CloseoutEdit = () =>
  import(/* webpackChunkName: "chunk-closeout" */ '@/pages/Closeout/CloseoutEdit.vue')

// Invoice
const InvoiceHome = () =>
  import(/* webpackChunkName: "chunk-invoice" */ '@/pages/Invoice/InvoiceHome.vue')
const InvoiceEdit = () =>
  import(/* webpackChunkName: "chunk-invoice" */ '@/pages/Invoice/InvoiceEdit.vue')

// InsertionOrder
const InsertionOrderHome = () =>
  import(
    /* webpackChunkName: "chunk-insertion-order" */ '@/pages/InsertionOrder/InsertionOrderHome.vue'
  )
const InsertionOrderEdit = () =>
  import(
    /* webpackChunkName: "chunk-insertion-order" */ '@/pages/InsertionOrder/InsertionOrderEdit.vue'
  )
const InsertionOrderView = () =>
  import(
    /* webpackChunkName: "chunk-insertion-order" */ '@/pages/InsertionOrder/InsertionOrderView.vue'
  )
const InsertionOrderUpload = () =>
  import(
    /* webpackChunkName: "chunk-insertion-order" */ '@/pages/InsertionOrder/InsertionOrderUpload.vue'
  )

// Agreement
const AgreementHome = () =>
  import(/* webpackChunkName: "chunk-agreement" */ '@/pages/Agreement/AgreementHome.vue')
const AgreementEdit = () =>
  import(/* webpackChunkName: "chunk-agreement" */ '@/pages/Agreement/AgreementEdit.vue')

// Booking
const BookingHome = () =>
  import(/* webpackChunkName: "chunk-booking" */ '@/pages/Booking/BookingHome.vue')
const BookingEdit = () =>
  import(/* webpackChunkName: "chunk-booking" */ '@/pages/Booking/BookingEdit.vue')

// Inventory
const InventoryHome = () =>
  import(/* webpackChunkName: "chunk-inventory" */ '@/pages/Inventory/InventoryHome.vue')
const InventoryEdit = () =>
  import(/* webpackChunkName: "chunk-inventory" */ '@/pages/Inventory/InventoryEdit.vue')

// ReportView
const ReportViewHome = () =>
  import(/* webpackChunkName: "chunk-view" */ '@/pages/ReportView/ReportViewHome.vue')
const ReportViewEdit = () =>
  import(/* webpackChunkName: "chunk-view" */ '@/pages/ReportView/ReportViewEdit.vue')

// Creative
const CreativeHome = () =>
  import(/* webpackChunkName: "chunk-creative" */ '@/pages/Creative/CreativeHome.vue')
const CreativeEdit = () =>
  import(/* webpackChunkName: "chunk-creative" */ '@/pages/Creative/CreativeEdit.vue')

// Forecast
const ForecastHome = () =>
  import(/* webpackChunkName: "chunk-forecast" */ '@/pages/Forecast/ForecastHome.vue')
const ForecastUpdate = () =>
  import(/* webpackChunkName: "chunk-forecast" */ '@/pages/Forecast/ForecastUpdate.vue')

// Audit
const AuditHome = () => import(/* webpackChunkName: "chunk-audit" */ '@/pages/Audit/AuditHome.vue')

// Adunit Scheduler
const AdunitSchedulerHome = () =>
  import(
    /* webpackChunkName: "chunk-scheduler" */ '@/pages/AdunitScheduler/AdunitSchedulerHome.vue'
  )

// Adunit Scheduler
const AdunitSchedulerEdit = () =>
  import(
    /* webpackChunkName: "chunk-scheduler" */ '@/pages/AdunitScheduler/AdunitSchedulerEdit.vue'
  )

// Unsubscribe
const Unsubscribe = () =>
  import(/* webpackChunkName: "chunk-unsubscribe" */ '@/pages/Unsubscribe/Unsubscribe.vue')

const MediaOceanEdit = () =>
  import(
    /* webpackChunkName: "chunk-mediaocean" */ '@/pages/MediaOcean/Invoice/MediaOceanInvoiceEdit.vue'
  )
const MediaOceanHome = () =>
  import(
    /* webpackChunkName: "chunk-mediaocean" */ '@/pages/MediaOcean/Invoice/MediaOceanInvoiceHome.vue'
  )

const AdOps = () => import(/* webpackChunkName: "chunk-ops" */ '@/pages/AdOps/ScheduleHome.vue')
const AdOpsDistribution = () =>
  import(
    /* webpackChunkName: "chunk-adops-distribution" */ '@/pages/AdOps/InventoryDistribution.vue'
  )

const MediaPackageHome = () =>
  import(
    /* webpackChunkName: "chunk-sales-paackages" */ '@/pages/Sales/Packages/MediaPackageHome.vue'
  )

const MediaPackageEdit = () =>
  import(
    /* webpackChunkName: "chunk-sales-paackages" */ '@/pages/Sales/Packages/MediaPackageEdit.vue'
  )

const MediaPlanHome = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanHome.vue'
  )

const MediaPlanEdit = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanEdit.vue'
  )
const MediaPlanView = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanView.vue'
  )

const Dashboard = () =>
  import(/* webpackChunkName: "chunk-dashboard" */ '@/pages/Dashboard/index.vue')

const TaskHome = () =>
  import(/* webpackChunkName: "chunk-dashboard" */ '@/pages/Task/TaskHome.vue')

const ReconciliationHome = () =>
  import(/* webpackChunkName: "chunk-reconciliation" */ '@/pages/Reconciliation/ReconciliationHome.vue')

const ReconciliationView = () =>
  import(/* webpackChunkName: "chunk-reconciliation-view" */ '@/pages/Reconciliation/ReconciliationView.vue')

const DynamicRateHome = () =>
  import(/* webpackChunkName: "chunk-dynamic-rate-home" */ '@/pages/DynamicRate/DynamicRateHome.vue')

const DynamicRateView = () =>
  import(/* webpackChunkName: "chunk-dynamic-rate-view" */ '@/pages/DynamicRate/DynamicRateView.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        secure: false,
      },
    },
    {
      path: '/unsubscribe/:id',
      name: 'Unsubscribe',
      component: Unsubscribe,
      props: true,
      meta: {
        secure: false,
      },
    },
    {
      path: '/app',
      name: 'Layout',
      component: Layout,
      children: [
        // main pages
        {
          path: 'insights',
          name: 'Insights',
          component: Insights,
          meta: {
            secure: true,
            model: 'insights',
            action: 'view',
            index: 'insights',
          },
        },
        {
          path: 'adops/monitoring',
          name: 'adops',
          component: AdOps,
          meta: {
            secure: true,
            model: 'adops',
            action: 'view',
            index: 'order_monitoring',
          },
        },
        {
          path: 'adops/distribution',
          name: 'inventory_distribution',
          component: AdOpsDistribution,
          meta: {
            secure: true,
            model: 'adops',
            action: 'view',
            index: 'inventory_distribution',
          },
        },
        {
          path: 'insights',
          name: 'Dashboard',
          component: Insights,
          meta: {
            secure: true,
            model: 'insights',
            action: 'view',
            index: 'insights',
          },
        },
        {
          path: 'my-dashboard',
          name: 'MyDashboard',
          component: Dashboard,
          meta: {
            secure: true,
            model: 'insights',
            action: 'view',
            index: 'my-dashboard',
          },
        },
        {
          path: 'mediaocean_invoices',
          name: 'MediaOceanInvoiceHome',
          component: MediaOceanHome,
          meta: {
            secure: true,
            model: 'media_ocean',
            action: 'view',
            index: 'mediaocean_invoices',
          },
        },
        {
          path: 'mediaocean_invoice/:id?',
          props: true,
          name: 'MediaOceanInvoiceEdit',
          component: MediaOceanEdit,
          meta: {
            secure: true,
            model: 'media_ocean',
            action: 'edit',
            index: 'mediaocean_invoices',
          },
        },
        {
          path: 'forecast',
          name: 'ForecastHome',
          component: ForecastHome,
          meta: {
            secure: true,
            model: 'forecast',
            action: 'view',
            index: 'forecast',
          },
        },
        {
          path: 'forecast/update',
          name: 'ForecastUpdate',
          component: ForecastUpdate,
          meta: {
            secure: true,
            model: 'forecast',
            action: 'edit',
            index: 'forecast',
          },
        },
        {
          path: 'companies',
          name: 'Companies',
          component: CompanyHome,
          meta: {
            secure: true,
            model: 'company',
            action: 'view',
            index: 'companies',
          },
        },
        {
          path: 'company/:id?',
          name: 'Company',
          component: CompanyEdit,
          props: true,
          meta: {
            secure: true,
            model: 'company',
            action: 'edit',
            index: 'companies',
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: UserHome,
          meta: {
            secure: true,
            model: 'user',
            action: 'view',
            index: 'users',
          },
        },
        {
          path: 'user/:id?',
          name: 'User',
          component: UserEdit,
          props: true,
          meta: {
            secure: true,
            model: 'user',
            action: 'edit',
            index: 'users',
          },
        },
        {
          path: 'profiles',
          name: 'Profiles',
          component: ProfileHome,
          meta: {
            secure: true,
            model: 'profile',
            action: 'view',
            index: 'profiles',
          },
        },
        {
          path: 'profile/:id?',
          name: 'Profile',
          component: ProfileEdit,
          props: true,
          meta: {
            secure: true,
            model: 'profile',
            action: 'edit',
            index: 'profiles',
          },
        },
        {
          path: 'account/:oauth?',
          name: 'Account',
          component: UserAccount,
          props: true,
          meta: {
            secure: true,
            index: 'account',
          },
        },
        {
          path: 'insertion_orders',
          name: 'InsertionOrderHome',
          component: InsertionOrderHome,
          props: true,
          meta: {
            secure: true,
            model: 'insertion_order',
            action: 'view',
            index: 'insertion_orders',
          },
        },
        {
          path: 'insertion_order/:id?',
          name: 'InsertionOrderEdit',
          component: InsertionOrderEdit,
          props: true,
          meta: {
            secure: true,
            model: 'insertion_order',
            action: 'edit',
            index: 'insertion_orders',
          },
        },
        {
          path: 'insertion_order/:id/view',
          name: 'InsertionOrderView',
          component: InsertionOrderView,
          props: true,
          meta: {
            secure: true,
            model: 'insertion_order',
            action: 'view',
            index: 'insertion_orders',
          },
        },
        {
          path: 'insertion_order/:id/upload',
          name: 'InsertionOrderUpload',
          component: InsertionOrderUpload,
          props: true,
          meta: {
            secure: true,
            model: 'insertion_order',
            action: 'edit',
            index: 'insertion_orders',
          },
        },
        {
          path: 'agreements',
          name: 'AgreementHome',
          component: AgreementHome,
          props: true,
          meta: {
            secure: true,
            model: 'agreement',
            action: 'view',
            index: 'agreements',
          },
        },
        {
          path: 'agreement/:id?',
          name: 'AgreementEdit',
          component: AgreementEdit,
          props: true,
          meta: {
            secure: true,
            model: 'agreement',
            action: 'edit',
            index: 'agreements',
          },
        },
        {
          path: 'terms',
          name: 'TermsHome',
          component: TermsHome,
          props: true,
          meta: {
            secure: true,
            model: 'term',
            action: 'view',
            index: 'terms',
          },
        },
        {
          path: 'term/:id?',
          name: 'TermsEdit',
          component: TermsEdit,
          props: true,
          meta: {
            secure: true,
            model: 'term',
            action: 'edit',
            index: 'terms',
          },
        },
        {
          path: 'reports',
          name: 'Reports',
          component: ReportHome,
          meta: {
            secure: true,
            model: 'report',
            action: 'edit',
            index: 'reports',
          },
        },
        {
          path: 'report/:id?',
          name: 'Report',
          component: ReportEdit,
          props: true,
          meta: {
            secure: true,
            model: 'report',
            action: 'view',
            index: 'reports',
          },
        },
        {
          path: 'closeouts',
          name: 'Closeouts',
          component: CloseoutHome,
          meta: {
            secure: true,
            model: 'closeout',
            action: 'view',
            index: 'closeouts',
          },
        },
        {
          path: 'closeout/:id?',
          name: 'Closeout',
          component: CloseoutEdit,
          props: true,
          meta: {
            secure: true,
            model: 'closeout',
            action: 'edit',
            index: 'closeouts',
          },
        },
        {
          path: 'invoices',
          name: 'Invoices',
          component: InvoiceHome,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'view',
            index: 'invoices',
          },
        },
        {
          path: 'invoice/:id?',
          name: 'Invoice',
          component: InvoiceEdit,
          props: true,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'edit',
            index: 'invoices',
          },
        },
        {
          path: 'views',
          name: 'ReportViews',
          component: ReportViewHome,
          props: true,
          meta: {
            secure: true,
            model: 'report_view',
            action: 'view',
            index: 'views',
          },
        },
        {
          path: 'view/:id?',
          name: 'ReportView',
          component: ReportViewEdit,
          props: true,
          meta: {
            secure: true,
            model: 'report_view',
            action: 'edit',
            index: 'views',
          },
        },
        {
          path: 'creatives',
          name: 'Creatives',
          props: true,
          component: CreativeHome,
          meta: {
            secure: true,
            model: 'creative',
            action: 'view',
            index: 'creatives',
          },
        },
        {
          path: 'creative/:id?',
          name: 'CreativeEdit',
          props: true,
          component: CreativeEdit,
          meta: {
            secure: true,
            model: 'creative',
            action: 'edit',
            index: 'creatives',
          },
        },
        {
          path: 'bookings',
          name: 'Bookings',
          props: true,
          component: BookingHome,
          meta: {
            secure: true,
            model: 'booking',
            action: 'view',
            index: 'booking',
          },
        },
        {
          path: 'booking/:id?',
          name: 'BookingEdit',
          props: true,
          component: BookingEdit,
          meta: {
            secure: true,
            model: 'booking',
            action: 'edit',
            index: 'booking',
          },
        },
        {
          path: 'schedulers',
          name: 'Schedulers',
          component: AdunitSchedulerHome,
          meta: {
            secure: true,
            model: 'scheduler',
            action: 'view',
            index: 'schedulers',
          },
        },
        {
          path: 'scheduler/:id?',
          name: 'Scheduler',
          props: true,
          component: AdunitSchedulerEdit,
          meta: {
            secure: true,
            model: 'scheduler',
            action: 'view',
            index: 'schedulers',
          },
        },
        {
          path: 'inventories',
          name: 'Inventories',
          component: InventoryHome,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'view',
          },
        },
        {
          path: 'inventory/:id?',
          name: 'InventoryEdit',
          props: true,
          component: InventoryEdit,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'edit',
            index: 'adunits',
          },
        },
        {
          path: 'sales/media_packages',
          name: 'MediaPackages',
          props: true,
          component: MediaPackageHome,
          meta: {
            secure: true,
            model: 'media_package',
            action: 'edit',
            index: 'media_packages',
          },
        },
        {
          path: 'sales/media_package/:id?',
          name: 'MediaPackageEdit',
          props: true,
          component: MediaPackageEdit,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'edit',
            index: 'media_packages',
          },
        },
        {
          path: 'sales/media_plans',
          name: 'MediaPlans',
          props: true,
          component: MediaPlanHome,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'view',
            index: 'media_plan',
          },
        },
        {
          path: 'sales/media_plan/:id?',
          name: 'MediaPlan',
          props: true,
          component: MediaPlanEdit,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'edit',
            index: 'media_plan',
          },
        },
        {
          path: 'sales/media_plan/:id/view/:mode?',
          name: 'MediaPlanView',
          component: MediaPlanView,
          props: true,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'view',
            index: 'media_plans',
          },
        },
        {
          path: 'tasks',
          name: 'TaskHome',
          props: true,
          component: TaskHome,
          meta: {
            secure: true,
            model: 'task',
            action: 'edit',
            index: 'tasks',
          },
        },
        {
          path: 'audit',
          name: 'Audit',
          component: AuditHome,
          meta: {
            secure: true,
            model: 'audit',
            action: 'view',
            index: 'adunits',
          },
        },
        {
          path: 'reconciliations',
          name: 'reconciliations',
          component: ReconciliationHome,
          meta: {
            secure: true,
            model: 'reconciliation',
            action: 'view',
            index: 'reconciliations',
          },
        },
        {
          path: 'reconciliation/:period',
          name: 'reconciliation-view',
          component: ReconciliationView,
          meta: {
            secure: true,
            model: 'reconciliation',
            action: 'edit',
            index: 'reconciliations',
          },
        },
        {
          path: 'sales/dynamic_rates',
          name: 'dynamic_rate',
          component: DynamicRateHome,
          meta: {
            secure: true,
            model: 'dynamic_rate',
            action: 'view',
            index: 'dynamic_rates',
          },
        },
        {
          path: 'sales/dynamic_rate/:id?',
          name: 'dynamic_rate_edit',
          component: DynamicRateView,
          meta: {
            secure: true,
            model: 'dynamic_rate',
            action: 'edit',
            index: 'dynamic_rates',
          },
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: ErrorPage,
      meta: {
        secure: false,
      },
    },
    {
      path: '/404',
      name: '404',
      component: ErrorPage,
      meta: {
        secure: false,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  let auth = false
  const systemModule = getModule(SystemtModule)
  if (systemModule.user) {
    auth = true
  }
  if (to.meta.secure) {
    if (!auth) {
      if (to.name != 'Login') {
        systemModule.updateState({
          name: 'target',
          data: to,
        })

        next({ name: 'Login' })
      }
    } else if (to.name == 'Login') {
      let home = systemModule.user?.home
      if (!home) home = 'Dashboard'
      next({ name: home })
    } else if (!to.meta.model || systemModule.user?.can(to.meta.model, to.meta.action)) {
      next()
    } else {
      next({ name: '404' })
    }
  } else {
    next()
  }
})

export default router
