












































































































































































import { Component, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import WebMessage from '../../models/WebMessage'
import User from '../../models/User'
import SystemtModule from '../../store/SystemModule'

@Component({
  components: {
    Widget,
  },
})
export default class LoginPage extends Vue {
  public errorMessage: string | null = null

  public processing = false

  public remember = false

  public step: string = 'login'

  public account = null

  public verification = null

  public username: string = ''

  public password: string = ''

  public get system_loading(): boolean {
    return getModule(SystemtModule).loading.system
  }

  public get app_env(): string {
    return getModule(SystemtModule).enviroment
  }

  public async login() {
    this.processing = true

    let token = ''
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // @ts-ignore
      token = await this.$recaptcha('login')
    }

    const api = new Api()
    api
      .post('user/login', {
        email: this.username,
        password: this.password,
        remember: this.remember,
        token,
      })
      .then(response => {
        // Parse and Store User object
        const user = User.toObject(response.data.result.user)

        const system = getModule(SystemtModule)

        system.updateState({
          name: 'user',
          data: user,
        })

        this.$store.commit('system/updateCSRF', response.data.result.csrf)

        // Store api token
        if (response.data.result.api_token) {
          this.$store.commit('system/updateApiToken', response.data.result.api_token)
        }

        if (response.data.result.token) {
          localStorage.setItem(response.data.result.token.name, response.data.result.token.value)
        } else {
          localStorage.removeItem('remember_token')
        }

        this.$store.dispatch('system/sync')
        this.processing = false

        if (this.username.includes('ismconnect')) {
          setTimeout(() => {
            WebMessage.warning(
              `Your email was updated from '${this.username}' to '${this.username.replace(
                '@ismconnect.com',
                '@castiron.media',
              )}'. Your access with the ISM email will be disabled on October 31st, please use your Cast Iron email instead.`,
            )
          }, 2000)
        }
      })
      .catch(e => {
        if (e.response && e.response.status === 429) {
          this.errorMessage = e.response.data.errors.email[0]
        } else this.errorMessage = 'Invalid Username or password'
        this.processing = false
      })
  }

  public forgotPassword() {
    this.step = 'reset'
  }

  public resetPassword() {
    const api = new Api()
    api
      .post('password', {
        email: this.account,
      })
      .then(() => {
        this.step = 'validate'
        WebMessage.success('Please confirm the validation code sent to your email address.')
      })
      .catch(() => {
        WebMessage.error('Invalid username! Please confirm the provided information.')
      })
  }

  public validatePassword() {
    const api = new Api()
    api
      .patch('password', {
        email: this.account,
        verification: this.verification,
      })
      .then(() => {
        this.step = 'login'
        WebMessage.success('Your new password was sent to your email.')
      })
      .catch(() => {
        this.verification = null
        WebMessage.error('Invalid validation code! A new code was sent to your email')
      })
  }

  public cancel() {
    this.step = 'login'
  }
}
