/** @format */

import ApiParameters from '@/models/interface/ApiParameters'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
// @ts-ignore
import * as FileSaver from 'file-saver'
import axios, { AxiosRequestConfig } from 'axios'
import store from '../store'
import WebMessage from './WebMessage'

export default class Api {
  private basePath: string = process.env.VUE_APP_BASE_API_URL || '/'

  private config: any = {
    timeout: 480000,
    headers: {},
  }

  public catch_error = true

  constructor(catch_error: boolean = true, config: any = null) {
    const system = getModule(SystemtModule)

    if (config) {
      for (const key in config) {
        if (Object.prototype.hasOwnProperty.call(config, key)) {
          this.config[key] = config[key]
        }
      }
    }

    if (system.user && system.api_token) {
      this.config.headers.Authorization = `Bearer ${system.api_token}`
    }

    this.catch_error = catch_error
  }

  public get(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config
    c.params = data

    return axios.get(this.basePath + path, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public post(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    return axios.post(this.basePath + path, data, this.config).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public patch(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    return axios.patch(this.basePath + path, data, this.config).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public delete(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    const c = this.config
    c.params = data
    return axios.delete(this.basePath + path, c).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public put(path: string, data: ApiParameters = {}, catch_response: boolean = true) {
    return axios.put(this.basePath + path, data, this.config).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public form(path: string, data: ApiParameters | FormData = {}, catch_response: boolean = true) {
    this.config.headers['Content-Type'] = 'multipart/form-data'
    this.config.timeout = 0
    let formData = null
    if (!(data instanceof FormData)) {
      formData = new FormData()

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key])
        }
      }
    } else {
      formData = data
    }

    return axios.post(this.basePath + path, formData, this.config).catch((error: any) => {
      if (catch_response && this.catch_error) {
        this.onError(error)
      }
      throw error
    })
  }

  public setHeader(headers: any) {
    this.config.headers = headers
  }

  private onError(error: any) {
    let message
    if (error.response && error.response.status == 422) {
      for (const key in error.response.data.errors) {
        message = error.response.data.errors[key][0]
        break
      }
    }

    if (!message && error.response && error.response.status == 503) {
      message = 'System maintenance in progress, please try again later.'
    }

    if (!message && error.response && error.response.status == 403) {
      store.dispatch('system/logout')
      message = "Your session expired or you don't have the required permissions to access this resource, please login again."
    }

    if (!message && !error.response) {
      message = 'We were not able to process your request, please check your internet connection and try again later.'
    }

    if (!message) {
      message = 'We were not able to process your request, please try again later.'
    }

    if (message) {
      WebMessage.error(message)
    }
  }

  public download(path: string, output_name: string) {
    const system = getModule(SystemtModule)

    const op = path.includes('?') ? '&' : '?'

    FileSaver.saveAs(
      `${process.env.VUE_APP_BASE_API_URL + path}${op}api_token=${system.api_token}`,
      output_name,
    )
  }
}
